import { Button, Divider } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { Component } from 'react'
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import commonData from '../../importanValue';
import DesktopMenu from '../DesktopMenu';
import Header from '../Header';

export default class Notifications extends Component {
    state = { isLoading: true, data: [], uid: "", notifications: [] };

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const token = Cookies.get("jwt_token");
        const headers = {
            "Content-Type": "application/json",
            authorization: token,
            "Access-Control-Allow-Origin": "*",
        };
        const uid = localStorage.getItem("num");
        const body = {
            type: "getExamsOfUser",
            search: "",
            qid: uid,
        };
        try {
            this.setState({ isLoading: true });

            const data = await axios.post(`${commonData["api"]}/support`, body, {
                headers,
            });
            console.log("🚀 ~ file: Notifications.js ~ line 36 ~ Notifications ~ getData= ~ data", data)
            this.setState({
                isLoading: false,
                data: data.data[0],
                notifications: data.data[1],
            });
        } catch (err) {
            NotificationManager.error(`Something Went Wrong`);
            this.setState({ isLoading: false });
        }
    };

    getNotificationMsg = (data) => {
        const { title, message, url } = JSON.parse(data)
        return <div>
            <p>Title :{title}</p>
            <p>Message : {message}</p>
            {/* {url && <p>URL : <a href={url} target={"_blank"}>Click here</a></p>} */}
        </div>
    }

    render() {
        const { isLoading, data, notifications } = this.state;
        console.log(data);
        return (
            <>
                {!isLoading && (
                    <>
                        <div className="desktopsidebar">
                            <div className="homedesktopsidebarmenuexamdetails">
                                <DesktopMenu />
                            </div>
                            <Header />

                            <Divider color="white" />
                            <div className="viewresultsdesktop">

                                <div style={{ display: "flex", flexDirection: window.innerWidth > 767 ? "row" : "column", padding: 10 }}>
                                    <div style={{ marginRight: 10, background: "black", width: "100%" }}>
                                        <p className="homepage-package-title" style={{ fontSize: 16, textAlign: "center", textDecoration: "none" }}>
                                            Latest Exams Updated today
                                        </p>
                                        <div style={{ padding: 10 }}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="tablehead">Exam Name</th>
                                                        <th className="tablehead">Action</th>
                                                    </tr>
                                                </thead>
                                                {data.length > 0 && data.map((each, indx) =>
                                                    <tbody key={`quizname${each.quiz_name}`}>
                                                        <td className="tabledata">
                                                            <p>{each.quiz_name}</p>
                                                        </td>
                                                        <td className="tabledata">
                                                            <div>
                                                                <Link
                                                                    to={`/exam-details/${window.btoa(
                                                                        each.quid + "$$_" + each.gid
                                                                    )}`}
                                                                    className="linkto"
                                                                >
                                                                    <div
                                                                        className="gotoexamsbtnhome"
                                                                        style={{ marginTop: -20 }}
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            className="btn header-btns attemptbtn examlistattempt"
                                                                        >
                                                                            Attempt
                                                                        </Button>
                                                                    </div>
                                                                </Link>

                                                            </div>
                                                        </td>
                                                    </tbody>

                                                )}
                                            </table>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: window.innerWidth > 767 ? 0 : 10, background: "black", width: "100%" }}>
                                        <p className="homepage-package-title" style={{ fontSize: 16, textAlign: "center", textDecoration: "none" }}>
                                            Latest Messages From Admin
                                        </p>
                                        <div style={{ padding: 10 }}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="tablehead">Message</th>

                                                    </tr>
                                                </thead>
                                                {notifications.length > 0 && notifications.map((each, indx) =>
                                                    <tbody key={`notifications${each.id}`}>
                                                        <td className="tabledata">
                                                            {this.getNotificationMsg(each.data)}
                                                        </td>

                                                    </tbody>

                                                )}
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                )
                }
            </>)

    }

}
