const commonData = {
  "2factorAPI": "a871f3f8-2733-11ec-a13b-0200cd936042", //live
  smstemp: "exams", //live
  // "2factorAPI": "3d34c42c-8686-11ec-b9b5-0200cd936042",
  // smstemp: "",
  api: "https://phpstack-702151-3516898.cloudwaysapps.com", // exams2
  apapi: "https://examsapi.navachaitanya.net", // testing

  // api: "http://localhost:4000",
  app: "https://educationchowrasta.com",
  referApp: "educationchowrasta.com",
  // app: "http://localhost:3000",
  payukey: "aYEdQEUZ",
  paysalt: "d2aOrQ4FGU",
  tinymice: "nvxe3e4bc3g47rvgm716zyb4h6w8mgd42rs4p8zptbbm84fi",
  // push
  // app_id: "466146a1-7165-41af-bca8-fa9ed63c0552", //nc exmas
  // auth_key: "OTllYWM4ZmItNWY4Ny00ZDRiLWFjM2YtMGZjMDc0ZDg2ZTBk", //nc exmas
  // app_id: "0086b69c-959f-49c5-9cd6-2a813a27fb00", // local
  // auth_key: "MGQ5MmJhNTktOTMyZC00OGEwLTlmMmQtZmFkMGExODhlOWY1", // local
  app_id: "04ca8db0-475a-477c-9d13-8106bc4d2154", //ts exmas
  auth_key: "ZDQzOTdhNDQtY2ZmNy00ZDYxLWFjZTQtNzZhMWMxZDA0Zjcy", //ts exmas
  // app_id: "b72c2c1d-9fca-4e90-b0c7-2a5458c3270b", //ap exmas
  // auth_key: "ZjkwMDg4YmEtMjgwZi00MDdkLTgxYmUtMjlkNDQzYjRjNzI2", //ap exmas
  userMenu: [
    {
      "url": "/",
      "name": "Home"
    },
    {
      "url": "/results-list",
      "name": "Result",
    },
    {
      "url": "/exams-schedules",
      "name": "Exam Schedule"
    },
    {
      "url": "/syllabus-in-telugu",
      "name": "Syllabus"
    },
    {
      "url": "/study-materials",
      "name": "Study Materials"
    },
    {
      "url": "/user-profile",
      "name": "My Profile",
    },
    // {
    //   "url": "/notifications",
    //   "name": "Notifications",
    // }
  ],
  AdminMenu: [
    {
      "url": "/",
      "name": "Home"
    },
    { url: "/admin/dashboard", name: "Dashboard" },
    { url: "/admin/users", name: "Users" },
    { url: "/admin/qbank/view-all", name: "QBank" },
    { url: "/admin/exams/view-all", name: "Exams List" },
    { url: "/admin/group/add-new-package", name: "Packages" },
    { url: "/admin/result/view-all", name: "Result List" },
    { url: "/admin/qbank/reported-questions/0/0", name: "Reported Questions" },
    { url: "/admin/qbank/categories", name: "Categories List" },
    { url: "/admin/others/coupons", name: "Discount Coupons" },
    // { url: "/admin/others/send-push", name: "Send Push" },
    // { url: "/admin/others/auto-responder", name: "Auto Responder" },
    { url: "/admin/user/referdata", name: "Refer Data" }
  ]

};

export default commonData;
