import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import { withRouter } from "react-router";
import "./styles.css";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BookIcon from "@mui/icons-material/Book";
import Divider from "@mui/material/Divider";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import OneSignal from "react-onesignal";
import Support from "./main/Support";
import commonData from "../importanValue";
// import { loadComponent } from "../App";
if (localStorage.getItem("user") === "1") {
  {
    OneSignal.sendTag("user", "admin");
  }
}
class DesktopMenu extends React.Component {
  logoutUser = () => {
    const { history } = this.props;
    Cookie.remove("jwt_token");
    // console.log(history);
    localStorage.removeItem("num");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("uid");
    localStorage.removeItem("name");
    history.replace("/login");
  };

  render() {
    // console.log(this.props);
    const token = Cookie.get("jwt_token");
    return (
      <div>
        <div>
          {/* <p className="heading-para telangana">తెలంగాణ </p> */}
          {/* <h1 className="heading-title desktoptitle">నవచైతన్య కాంపిటీషన్స్</h1> */}
          <p className="heading-para desktoppara">EDUCATION CHOWRASTA</p>
          <Divider color="white" style={{ marginTop: 15 }} />


          {commonData.userMenu.map((eachMen) => <Link to={eachMen.url} className="linkto" >
            <div className="deskmenustyle" style={{ marginTop: 15 }}>
              <div className="deskmenuIcon">➤</div>
              <p className="deskmenuPara">{eachMen.name}</p>
            </div>
          </Link>)}



          {token !== undefined && (
            <>
              {localStorage.getItem("user") === "1" && (
                <>
                  {commonData.AdminMenu.map((eachMen) =>
                    <Link to={eachMen.url} className="linkto">
                      <div className="deskmenustyle" style={{ marginTop: 15 }}>
                        <div className="deskmenuIcon">➤</div>
                        <p className="deskmenuPara">{eachMen.name}</p>
                      </div>
                    </Link>
                  )}
                </>
              )}
              <div className="desksidemenulogout">
                <Button
                  variant="contained"
                  className="btn header-btns"
                  onClick={this.logoutUser}
                >
                  <div className="deskmenustyle">
                    <div className="deskmenuIcon">
                      <LogoutIcon />
                    </div>
                    <p className="deskmenuPara">Logout</p>
                  </div>
                </Button>
              </div>
            </>
          )}
        </div>
        {/* <Support /> */}
      </div>
    );
  }
}

export default withRouter(DesktopMenu);