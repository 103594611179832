import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage/session';
import common from "./reducer";

const persistConfig = {
  key: 'root',
  storage,
}

// const persistedReducer = persistReducer(persistConfig, common)
export default configureStore({
  reducer: { common: common },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
