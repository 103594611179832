import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";
import Button from "@mui/material/Button";
import { Link, useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import { withRouter } from "react-router";
import HomeIcon from "@mui/icons-material/Home";
import commonData from "../importanValue";
import OneSignal from "react-onesignal";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceWalletIcon from "@mui/icons-material/CurrencyRupee";
if (localStorage.getItem("user") === "1") {
  {
    OneSignal.sendTag("user", "admin");
  }
}
const Header = (props) => {
  const [menu, openMenu] = React.useState(false);
  const token = Cookie.get("jwt_token");
  const history = useHistory();
  // console.log(props);
  const logoutUser = (props) => {
    Cookie.remove("jwt_token");
    localStorage.removeItem("num");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("uid");
    localStorage.removeItem("name");
    history.replace("/login");
  };
  return (
    <div className="header-container">
      {/* <p className="heading-para telangana">తెలంగాణ </p>
      <h1 className="heading-title">నవచైతన్య కాంపిటీషన్స్</h1>
      <p className="heading-para">చింతలపూడి , ఏలూరు జిల్లా</p> */}
      <p className="heading-para desktoppara">Online Exams</p>
      <nav className="navbar">
        <div className="nav-buttons">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Link to="/" className="linkto">
              <HomeIcon
                className="home-button marg"
                style={{ color: "black" }}
              />
            </Link>

            {/* <Link to="/user-profile" className="linkto">
              <AccountCircleIcon
                className="home-button marg"
                style={{ marginLeft: 25, color: "orange" }}
              />
            </Link> */}

            {/* <Link to="/user/user-wallet" className="linkto">
              <p className="rupee-icon">
                ₹{" "}
                <span className="rupee-icon-span">
                  {localStorage.getItem("userWallet") === undefined
                    ? 0
                    : localStorage.getItem("userWallet")}
                </span>
              </p>
            </Link> */}

            {token !== undefined && (
              <>
                {/* <LogoutIcon
                  className="home-button marg"
                  style={{ marginLeft: 25 }}
                  onClick={logoutUser}
                /> */}
                {/* <hr style={{ margin: 0 }} /> */}
              </>
            )}
          </div>

          <div style={{ display: "flex" }}>
            {/* <p className="menu-parar" onClick={() => openMenu(!menu)}>
              Main Menu
            </p> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <MenuIcon
                className="mobile-icon"
                fontSize="large"
                onClick={() => openMenu(!menu)}
              />
            </div>
          </div>
        </div>
        {menu ? (
          <div>
            {commonData.userMenu.map((eachMen) => <Link to={eachMen.url} className="linkto">
              <Button variant="contained" className="btn header-btns">
                {eachMen.name}
              </Button>
            </Link>)}

            {token !== undefined && localStorage.getItem("user") === "1" && (
              commonData.AdminMenu.map((eachMen) =>
                <Link to={eachMen.url} className="linkto">
                  <Button variant="contained" className="btn header-btns">
                    {eachMen.name}
                  </Button>
                </Link>
              )
            )}
            <Button
              variant="contained"
              className="btn header-btns"
              onClick={logoutUser}
            >
              Logout
            </Button>


          </div>
        ) : null}
      </nav>
    </div>
  );
};

export default withRouter(Header);