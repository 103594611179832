import React, { Component } from "react";
import { io } from 'socket.io-client'
import Loader from "../Loader";
import axios from "axios";
import Cookie from "js-cookie";
import sendpush from "../../components/admin/sendNotification";
import commonData from "../../importanValue";
import audioFile from "../../../src/sent.mp3";
import recieve from "../../../src/recieve.mp3";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import "./chatStyles.css";
const socket = io(commonData["api"]);

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      Chatdata: [],
      message: "",
      to: "6305915902",
      from: "",
      lastId: 0,
    };
  }

  componentDidMount() {
    this.getData();
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    const uid = localStorage.getItem("num");
    socket.emit("user", uid)
    socket.on("msg", (socket2) => console.log("socket", socket2))
    // socket.emit("user", () => console.log("socket", socket))


  }
  // componentDidUpdate() {
  //   const newMsg = socket.on("newmessage", (data) => {
  //     console.log("new msgs", data)
  //     this.setState(p => ({ Chatdata: [...p.Chatdata, data[0]] }), this.scrollToBottom)
  //   })
  // }
  scrollToBottom = () => {
    const element = document.getElementById("messageend");
    element.scrollIntoView();
    // this.timer();
  };

  getData = async () => {
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const uid = localStorage.getItem("num");
    const body = {
      type: "getUserChat",
      search: "",
      qid: uid,
    };
    try {
      this.setState({ isLoading: true });

      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      if (data.data[0].length > 0) {
        console.log(data.data[0].filter((e) => e.type === "recieve"));
        this.setState(
          {
            isLoading: false,
            Chatdata: data.data[0],
            lastId:
              data.data[0].filter((e) => e.type === "recieve").length > 0 &&
              data.data[0].filter((e) => e.type === "recieve").slice(-1)[0].id,
          },
          this.scrollToBottom
        );
      } else {
        this.setState({
          isLoading: false,
        });
      }
      const newMsg = socket.on("newmessage", (data) => {
        console.log("new msgs", data)
        this.setState(p => ({ Chatdata: [...p.Chatdata, data[0]] }), this.scrollToBottom)
      })
    } catch (err) {
      console.log(err);
      // NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  sendMsg = async (e) => {
    e.preventDefault();
    const { message } = this.state;
    // socket.emit("messagetoAdmin", message)
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const uid = localStorage.getItem("num");
    // from = user === "1" ? "Admin" : uid;
    // to = user === "1" ? to : "Admin";
    const body = {
      type: "sendUserMsg",
      search: message,
      qid: `${uid}_${"Admin"}_${new Date().toLocaleTimeString()}`,
    };

    socket.emit("sentmessage", body)

    try {
      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      await sendpush({
        title: `New Message From Student...`,
        message: `Message : ${message}\nFrom : ${uid}\n`,
        filters: [
          {
            field: "tag",
            key: "user",
            relation: "=",
            value: "admin",
          },
        ],
        url: `/support/user/${uid}`,
      });
      this.setState(
        (p) => ({
          Chatdata: [...p.Chatdata, data.data[0][0]],
          message: "",
        }),
        () => {
          this.scrollToBottom();
          const audio = new Audio(audioFile);
          audio.play();
        }
      );
    } catch (err) {
      console.log(err);
      // NotificationManager.error(`Something Went Wrong`);
      this.setState({ isLoading: false });
    }
  };

  // timer = () => {
  //   this.countdown = setInterval(
  //     // () => {
  //     //   if (this.state.lastId > 0) {
  //     //     this.checkForNewMessage();
  //     //   }
  //     // },
  //     this.checkForNewMessage,
  //     3000
  //   );
  // };
  componentWillUnmount() {
    clearInterval(this.countdown);
  }
  checkForNewMessage = async (e) => {
    // clearInterval(this.countdown);
    const { message } = this.state;
    console.log("message", message);
    const uid = localStorage.getItem("num");
    const { lastId, Chatdata } = this.state;
    const token = Cookie.get("jwt_token");
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
      "Access-Control-Allow-Origin": "*",
    };
    const body = {
      type: "checkForNewMessage",
      search: lastId,
      qid: uid,
    };
    try {
      const data = await axios.post(`${commonData["api"]}/support`, body, {
        headers,
      });
      // console.log(data.data[0].filter((e) => e.type === "recieve"));

      if (data.data[0][0].result === "true") {
        const old = Chatdata;
        let newData = data.data[0].filter((e) => !old.includes(e));

        this.setState(
          (p) => ({
            Chatdata: old.concat(newData),
            message: message,
            lastId:
              data.data[0].filter((e) => e.type === "recieve").length > 0 &&
              data.data[0].filter((e) => e.type === "recieve").slice(-1)[0].id,
          }),
          () => {
            this.scrollToBottom();
            const audio = new Audio(recieve);
            audio.play();
          }
        );
      }
    } catch (err) {
      // NotificationManager.error(`Something Went Wrong`);
      console.log(err);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, Chatdata, message } = this.state;
    const uid = localStorage.getItem("num");
    return (
      <>
        {!isLoading && (
          <div className="chatmainDiv">
            <div className="chatbackground">
              {Chatdata.length > 0 && (
                <div>
                  {Chatdata.map((eachMsg) => (
                    <div
                      className={`chat ${eachMsg.from === uid ? "sentchat" : "recievechat"
                        }`}
                    >
                      <span className={`fromchat `}>
                        {eachMsg.from} ({" " + eachMsg.senttime} )
                      </span>

                      <p
                        className={`msg ${eachMsg.from === uid ? "sentChhat" : "recieveChhat"
                          }`}
                        dangerouslySetInnerHTML={{ __html: eachMsg.message }}
                      ></p>
                    </div>
                  ))}
                  <div
                    id="messageend"
                    style={{ float: "left", clear: "both" }}
                  ></div>
                </div>
              )}
              {Chatdata.length === 0 && (
                <div className="nomsgs">No Messages..</div>
              )}
            </div>{" "}
            <div className="chatMsgDiv">
              <form onSubmit={this.sendMsg}>
                <input
                  type={"text"}
                  className="chatmsginput"
                  required
                  placeholder="Message"
                  value={message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
                <input type={"submit"} className="chatsendbtn" value={"➤"} />
              </form>
            </div>
          </div>
        )}
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        )}

        <div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}

export default Chat;
