import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    packageList: [],
    activatedPackages: [],
    userInfo: [],
    supportHomeData: [],
    homeAutoData: [],
    categories: []
  },
  reducers: {
    storeinfo: (state, action) => {
      return { ...state, ...action.payload }
    }
  },
});

export const {
  storeinfo
} = commonSlice.actions;

export default commonSlice.reducer;
