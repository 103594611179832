import { Redirect, Route } from "react-router-dom";
import Cookie from "js-cookie";
import { useLocation } from "react-router-dom";
import OneSignal from "react-onesignal";

const ProtectedRoute = (props) => {
  const token = Cookie.get("jwt_token");
  const location = useLocation();
  console.log("location", location.search);
  if (token === undefined) {
    // const next = localStorage.getItem("prev");
    // // alert("దయచేసి ముందుగా లాగిన్ చేయండి");
    if (String(location.search).includes("?refer=")) {
      localStorage.setItem("refer", location.search.split("?refer=")[1]);
    }
    return <Redirect to={`/login/?next=${location.pathname}`} />;
  }
  return <Route {...props} />;
};

export default ProtectedRoute;
